




































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";

import BaseComponent from "@/components/base-component.vue";
import Select from "@rr-component-library/select/src/main";
import { CartState } from "@/store/modules/cart";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "MyAccount",
  components: {
    Select,
  },
})
export default class MyAccount extends BaseComponent {
  
  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  options = [
    { id: 'my-orders', name: this.$t('myAccount/navOrders').toString(), icon: 'shopping-bag' },
    { id: 'divider', icon: '' },
    { id: 'logout', name: this.$t('myAccount/navLogout').toString(), icon: 'logout' },
  ];

  currentOption: any = 'my-orders';
  currentOptionIcon: string = '';

  get keycloak() {
    return this.settingsState.keycloak;
  }

  get isAuthenticated() {
    return this.settingsState.isAuthenticated;
  }

  get routeName() {
    return this.$route.name;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get locales(): string {
    const defaultLocales: string = 'ru';
    if (!this.settingsState.languages) return defaultLocales;
    const langs = Object.assign([], this.settingsState.languages);
    if (langs) {
      const items: string[] = langs.filter((i: string) => i !== this.settingsState.lang);
      return [this.settingsState.lang, ...items].join(' ');
    }
    return defaultLocales;
  }

  get mobileOptions() {
    return this.options.filter((i: any) => i.id !== 'divider');
  }

  get showNav(): boolean {
    return this.routeName !== 'my-order' || (this.routeName === 'my-order' && !this.smOnly);
  }

  @Watch("routeName")
  onRouteNameChanged() {
    if (this.$route.name === 'my-account') {
      this.onOptionSelected('my-orders');
      return;
    }
    this.currentOption = this.$route.name;
  }

  @Watch("lang")
  async onLangChanged() {
    window.location.reload();
  }

  onOptionSelected(option: string) {
    if (option === 'divider') return;
    if (option === 'logout') {
      this.onLogout();
      return;
    }
    this.currentOption = option;
    const icon: any[] = this.options.filter((i) => i.id === option);
    this.currentOptionIcon = icon.length > 0 ? icon[0].icon: '';
    this.$router.push({ name: option }).catch(() => {});
  }

  onLogout() {
    if (this.keycloak) this.keycloak.logout({ redirectUri: window.location.origin });
  }

  onNeedAuth() {
    if (this.keycloak) {
      this.keycloak.login({
        redirectUri: window.location.href,
        scope: 'openid profile email',
        flow: 'implicit',
        locale: this.locales,
      });
    } else {
      this.$router.push({ name: 'home' });
    }
  }
  
  async mounted() {
    this.$store.state.buttonHome = true;
    this.$store.state.buttonBack = false;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = true;

    await this.cartState.loadCartId();

    if (this.cartState.cartId !== "") { 
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      }
    }

    if (!this.isAuthenticated) {
      if (this.keycloak) {
        this.keycloak.login({
          redirectUri: window.location.href,
          scope: 'openid profile email',
          flow: 'implicit',
          locale: this.locales,
        });
      } else {
        this.$router.push({ name: 'home' });
      }
      return;
    }

    if (this.$route.name === 'my-account') {
      this.onOptionSelected('my-orders');
    } else {
      this.currentOption = this.$route.name;
      const icon: any[] = this.options.filter((i) => i.id === this.currentOption);
      this.currentOptionIcon = icon.length > 0 ? icon[0].icon: '';
    }
  }
}
