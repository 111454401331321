import { render, staticRenderFns } from "./my-account.vue?vue&type=template&id=14efd36d&scoped=true&"
import script from "./my-account.vue?vue&type=script&lang=ts&"
export * from "./my-account.vue?vue&type=script&lang=ts&"
import style0 from "./my-account.vue?vue&type=style&index=0&id=14efd36d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14efd36d",
  null
  
)

export default component.exports